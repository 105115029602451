import { rawHttp } from '@/http';

export default class LogService {
  /**
   * @param {String} deploymentId UUID
   * @param {?Object} options
   * @property {?Number} options.limit
   * @property {?String} options.since - datetime in ISO format
   * @property {?String} options.until - datetime in ISO format
   * @returns {Promise<any>}
   */
  static async getLogs(deploymentId, options = {}) {
    const response = await rawHttp.get(`/deployments/${deploymentId}/logs`, { params: options });
    return response.data;
  }
}
