<template>
  <ciam-deployment-logs :deployment="deployment">
    <template v-slot:loading>
      <ciam-card-content-line type="full-width">
        <template v-slot:content>
          <ciam-loader class="py-4"></ciam-loader>
        </template>
      </ciam-card-content-line>
    </template>
  </ciam-deployment-logs>
</template>

<script>
import CiamCardContentLine from '@/components/CiamCardContentLine';
import CiamDeploymentLogs from '@/pages/deployments/logs/CiamDeploymentLogs';

export default {
  props: {
    deployment: {
      type: Object,
      required: true,
    },
  },
  name: 'DeploymentLogs',
  components: {
    CiamCardContentLine,
    CiamDeploymentLogs,
  },

  updated() {},

  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
ul.ciam-logs {
  height: 50vh;
  resize: vertical;

  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

ul.ciam-logs li {
  line-height: normal;
  font-family: SourceCodePro, monospace;
  font-size: 0.9em;
}

ul.ciam-logs li:hover,
ul.ciam-logs li:hover span:first-child {
  background: #fafad2;
  color: #0a0a0a;
}

/* timestamp */
ul.ciam-logs li span:first-child {
  color: #fff;
  padding: 0 5px;
  margin: 1px 0;
  background: #bcc2d1;
  border-radius: 2px;
  display: inline-block;
}
</style>
