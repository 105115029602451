<template>
  <div>
    <Promised :promise="logRequest">
      <template v-slot:pending>
        <slot name="loading"></slot>
      </template>

      <template v-slot:rejected="problem">
        <ciam-alert
          v-if="problem.status === 403"
          :title="problem.title"
          :description="getUpsellAlertDescription()"
          :type="AlertStatus.WARNING_HREF"
          :href-text="$t('upsell.email.contact')"
          :href="getUpsellAlertHref()"
        ></ciam-alert>
        <ciam-alert
          v-else-if="problem.status"
          :title="problem.title"
          :description="problem.detail"
          :type="AlertStatus.ALERT"
        ></ciam-alert>
      </template>

      <template>
        <ul ref="container" class="ciam-logs ml-2 mr-2" v-if="logLines.length > 0">
          <li v-for="(message, index) in logLines" :key="index">
            <ciam-clipboard :show-icon="false"><span v-once>{{ message | skip(message.indexOf('Z') + 1) | removeAnsi }}</span></ciam-clipboard>
          </li>
        </ul>
        <ciam-alert
          v-else-if="logLines.length == 0"
          :title="$t('deployment.logs.noLogTitle')"
          :description="$t('deployment.logs.noLogDescription')"
          :type="AlertStatus.INFO"
        ></ciam-alert>
      </template>
    </Promised>
  </div>
</template>

<script>
/**
 * Features:
 * - [x] ux: remove ansi code from logs
 * - [x] ux: keep the scrollbar at the same position when data updates occurs
 * - [x] ux: keep the scrollbar at the bottom (even on new data) if it there
 * - [x] ux: convert datetime in browser local time
 * - [x] perf: only render log lines on-time
 */

import LogService from './LogService';
import { AlertStatus } from '@/components/CiamAlert';
import CiamText from '@/components/CiamText';
import { is, last, uniq } from 'ramda';
import CiamAlert from '@/components/CiamAlert';
import moment from 'moment';
import UpsellFeatureService from '@/pages/UpsellFeatureService';
import i18n from '@/i18n';
import CiamClipboard from '@/components/CiamClipboard.vue';

export default {
  props: {
    deployment: {
      type: Object,
      required: true,
    },
  },
  name: 'ciam-deployment-logs',
  components: {
    CiamClipboard,
    CiamAlert,
  },
  data() {
    return {
      AlertStatus: AlertStatus,
      /**
       * @type {String}
       */
      since: moment().subtract(1, 'hours').format(),
      /**
       * @type {Number}
       */
      refreshInterval: process.env.LOGS_REFRESH_INTERVAL ? parseFloat(process.env.LOGS_REFRESH_INTERVAL) : 10000,

      timeoutHandler: null,

      // 1/ only display the loading and error-screen at screen load-time
      /**
       * @type {Promise<String[]>}
       */
      logRequest: new Promise(() => {}),

      logLines: [],

      scrollTop: null,
    };
  },
  mounted() {
    this.stop();
    this.refresh();
  },
  beforeUnmount() {
    this.stop();
  },
  beforeDestroy() {
    this.stop(); // because you never know
  },
  // after every rendering
  updated() {
    this.$nextTick(() => {
      // since $nextTick could be once the component does not exist anymore (thus this.$refs will be empty and this.$refs.container will be undefined)
      // we have to wrap it inside a try/catch
      try {
        this.$refs.container.scrollTop = this.scrollTop;
      } catch (err) {
        // do nothing
      }
    });
  },

  methods: {
    getUpsellAlertHref() {
      return UpsellFeatureService.getEmailHref(i18n.t(`deployment.logs.featureName`));
    },

    getUpsellAlertDescription() {
      return UpsellFeatureService.getEmailDescription(i18n.t(`deployment.logs.featureName`));
    },

    hasSlot(name = 'default') {
      return !!this.$slots[name];
    },
    storeScrollbarState() {
      if (!this.$refs.container) {
        // on first load, $refs are not available
        this.scrollTop = Number.MAX_SAFE_INTEGER;
        return;
      }

      // consider that the scrollbar must stick at the bottom if its lower than 80% of the available viewport
      const keepScrollbarAtBottom = this.$refs.container.scrollTop / this.$refs.container.scrollHeight > 0.8;
      this.scrollTop = keepScrollbarAtBottom ? Number.MAX_SAFE_INTEGER : this.$refs.container.scrollTop || 0;
    },

    refresh() {
      this.stop();
      LogService.getLogs(this.deployment.id, this.since === null ? { limit: 100 } : { since: this.since })
        .then(
          /**
           *
           * @param {String[] | any} logLines
           */
          (logLines) => {
            if (!Array.isArray(logLines) || logLines.length === 0) {
              this.logRequest = Promise.resolve([]);
              return;
            }

            // 2/ then only refresh the promise (thus the screen) ONCE the current request has some new data

            // update temporal cursor
            this.since = last(logLines).split(' ')[0];

            // merge back the data together
            // merge them (concat) with the latest log lines
            // remove duplicates lines (it can happen) with uniq
            this.storeScrollbarState();
            this.logLines = uniq(this.logLines.concat(logLines));

            this.logRequest = Promise.resolve([]);
          },
          (error) => {
            this.logLines = [];

            if (error.isAxiosError && is(Object, error.response) && is(Object, error.response.data)) {
              this.logRequest = Promise.reject(error.response.data);
            }
          }
        )
        .finally(() => {
          // either the request was a success or an error, delay next refresh
          this.timeoutHandler = setTimeout(this.refresh.bind(this), this.refreshInterval);
        });
    },

    stop() {
      clearTimeout(this.timeoutHandler);
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
ul.ciam-logs {
  height: 80vh;
  resize: vertical;

  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  color: black;
}

ul.ciam-logs li {
  line-height: normal;
  font-family: SourceCodePro, monospace;
  font-size: 0.9em;
  margin-bottom: 10px;
}

ul.ciam-logs li:hover,
ul.ciam-logs li:hover {
  background: #dbdbdb;
  color: black;
}
</style>